<template>
  <div>
    <form v-on:submit.prevent="transfer" class="column max">
      <b-field label="ID" horizontal>
        {{ device.id }}
      </b-field>

      <b-field label="Name" horizontal>
        {{ device.name }}
      </b-field>

      <b-field label="Label" horizontal>
        {{ device.label }}
      </b-field>

      <b-field label="New organization" horizontal>
        <OrganizationSelect v-model="organization_id" style="max-width: 400px" clearable />
      </b-field>

      <b-field label="New group" horizontal>
        <b-select v-if="organization_id" placeholder="Select a name" v-model="group_id">
          <option v-for="group in groups" v-bind:key="group.id" v-bind:value="group.id">{{ group.name }}</option>
        </b-select>
        <template v-else>Please select organization first</template>
      </b-field>

      <b-field label="" horizontal>
        <b-checkbox v-model="delete_messages">
          Delete device messages (otherwise they will be moved as well)
        </b-checkbox>
      </b-field>

      <b-field label="" horizontal>
        <b-checkbox v-model="change_token"> Change device API token </b-checkbox>
      </b-field>

      <b-field label="" horizontal>
        <b-button type="is-link" icon-left="folder-swap-outline" native-type="submit">Transfer device</b-button>
      </b-field>
    </form>

    <b-loading is-full-page :active.sync="loading"></b-loading>
  </div>
</template>

<script>
import Device from '../models/Device';
import Group from '../models/Group';
import OrganizationSelect from '../components/OrganizationSelect.vue';

export default {
  name: 'device-transfer',
  components: { OrganizationSelect },
  data() {
    return {
      device: {},
      organization_id: null,
      group_id: null,
      delete_messages: false,
      change_token: false,
      groups: [],
      loading: false,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      const { data } = await Device.getOne(this.$route.params.id);
      this.device = data;
    },
    async fetchGroups() {
      this.group_id = null;
      if (!this.organization_id) return;

      const { data } = await Group.getAll(this.organization_id);
      this.groups = data;

      if (this.groups.length === 1) {
        this.group_id = this.groups[0].id;
      }
    },
    async transfer() {
      if (this.device.id) {
        this.loading = true;
        const { data } = await Device.transfer({
          id: this.device.id,
          group_id: this.group_id,
          delete_messages: this.delete_messages,
          change_token: this.change_token,
        });
        this.$router.push({ name: 'devices', params: { groupId: data.group_id } });
        this.loading = false;
      }
      this.$toast.success('Device was transfered');
    },
  },
  watch: {
    organization_id() {
      this.fetchGroups();
    },
  },
};
</script>
