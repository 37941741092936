/* eslint-disable func-names */
<template>
  <b-autocomplete
    :data="data"
    placeholder="Select ..."
    field="name"
    :open-on-focus="true"
    :expanded="true"
    :loading="isFetching"
    :check-infinite-scroll="true"
    @typing="getAsyncData"
    @select="onSelect"
    @infinite-scroll="getMoreAsyncData"
    v-model="text"
    :size="size"
    :clearable="clearable"
  >
  </b-autocomplete>
</template>
<script>
import debounce from 'lodash/debounce';

export default {
  props: {
    value: {
      required: true,
    },
    dennyIds: {
      type: Array,
      default: () => [],
    },
    size: {},
    clearable: {},
  },
  data() {
    return {
      text: '',
      data: [],
      isFetching: false,
      name: null,
      offset: 0,
      total: 0,
      limit: 20,
    };
  },
  mounted() {
    this.getAsyncData('');
  },
  methods: {
    getAsyncData: debounce(function (name) {
      if (this.name !== name) {
        this.name = name;
        this.data = [];
        this.offset = 0;
        this.total = 0;
      }

      if (this.offset > this.total) {
        return;
      }
      this.isFetching = true;

      this.$http
        .get('/v1/organizations', {
          params: {
            name,
            offset: this.offset,
            limit: this.limit,
            sort_field: 'name',
            fields: 'name',
          },
        })
        .then((res) => {
          res.data.forEach((item) => {
            if (this.dennyIds.indexOf(item.id) < 0) this.data.push(item);
          });
          this.total = parseInt(res.headers['x-total'], 10);
          this.offset += this.limit;
        })
        .catch((error) => {
          this.data = [];
          throw error;
        })
        .finally(() => {
          this.isFetching = false;
        });
    }, 500),
    getMoreAsyncData: debounce(function () {
      this.getAsyncData(this.name);
    }, 250),
    onSelect(option) {
      this.$emit('input', option === null ? null : option.id);
    },
  },
  watch: {
    value(value) {
      console.log('value', value);
      if (value === null) {
        this.text = '';
      }
    },
  },
};
</script>
